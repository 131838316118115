import React from "react"

const Location1Icon = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12.4761C23 7.51915 18.971 3.5 14 3.5C9.02902 3.5 5 7.51915 5 12.4761C5 16.0086 7.17918 20.2724 11.4804 25.336C12.6573 26.7216 14.7395 26.8948 16.1311 25.7229C16.2712 25.6049 16.4011 25.4755 16.5196 25.336C20.8208 20.2724 23 16.0086 23 12.4761ZM14.8395 24.2024C14.2913 24.6641 13.4711 24.5958 13.0074 24.05C8.98341 19.3127 7 15.4319 7 12.4761C7 8.61815 10.1344 5.49141 14 5.49141C17.8656 5.49141 21 8.61815 21 12.4761C21 15.4319 19.0166 19.3127 14.9926 24.05C14.9459 24.105 14.8947 24.1559 14.8395 24.2024ZM10 12.4613C10 10.2622 11.7914 8.47851 14 8.47851C16.2086 8.47851 18 10.2622 18 12.4613C18 14.6604 16.2086 16.4441 14 16.4441C11.7914 16.4441 10 14.6604 10 12.4613ZM12 12.4613C12 13.5606 12.896 14.4527 14 14.4527C15.104 14.4527 16 13.5606 16 12.4613C16 11.3621 15.104 10.4699 14 10.4699C12.896 10.4699 12 11.3621 12 12.4613Z"
        fill="#98A2B3"
      />
    </svg>
  )
}

export default Location1Icon
