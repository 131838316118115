export const priceSeparator = (price: number, t?: any) => {
  if (price < 1000) {
    return price
  }
  if (price < 1000000) {
    return (price / 1000)?.toString()?.includes(".")
      ? (price / 1000).toFixed(1) + " " + t("ming")
      : (price / 1000).toFixed(0) + " " + t("ming")
  }
  if (price < 1000000000) {
    return (price / 1000000)?.toString()?.includes(".")
      ? (price / 1000000).toFixed(1) + " " + t("mln")
      : (price / 1000000).toFixed(0) + " " + t("mln")
  }
  if (price < 100000000000) {
    return (price / 1000000000)?.toString()?.includes(".")
      ? (price / 1000000000).toFixed(1) + " " + t("mlrd")
      : (price / 1000000000).toFixed(0) + " " + t("mlrd")
  }
  return (price / 1000000000000)?.toString()?.includes(".")
    ? (price / 1000000000000).toFixed(1) + " " + t("trln")
    : (price / 1000000000000).toFixed(0) + " " + t("trln")
}
