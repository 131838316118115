import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import {
  BannerImageType,
  IBanner,
  IBannerImage,
} from "pages/app/utils/models/banner"
import React from "react"

import styles from "./banner.module.scss"
import { urls } from "utils/constants/urls"

interface IProps {
  banner?: IBanner<Record<BannerImageType, IBannerImage>> | undefined
}

const Banner: React.FC<IProps> = ({ banner }) => {
  const t = useTranslations("body.mainTop")
  const { locale } = useRouter()
  return (
    <div>
      {(banner?.title[locale as keyof typeof banner.title] ||
        banner?.subtitle[locale as keyof typeof banner.subtitle]) && (
        <a
          className={`sm:px-4 sm:py-3 p-3 flex rounded-2xl items-start w-full gap-4 bg-[#FFFFFF4D] mt-5 sm:flex-row flex-col sm:w-max ${styles.bannerMain}`}
          style={{ backdropFilter: "blur(20px)" }}
          target={banner?.blank ? "_blank" : "_self"}
          href={`${urls.BASE_ADS_UYSOT}/api/banner/view?uuid=${banner?.uuid}&lang=${locale}`}
        >
          <div
            className={`w-auto h-12 rounded-xl flex items-center justify-center text-white font-medium text-base overflow-hidden ${styles.bannerLogo}`}
          >
            {banner?.logo && (
              <img src={banner?.logo} alt="err" className="w-full h-full" />
            )}
          </div>
          <div className="flex flex-col gap-1.5 sm:w-max w-full">
            <div className="flex items-center justify-between gap-20">
              <p className="text-base text-white font-semibold">
                {banner?.title[locale as keyof typeof banner.title]}
              </p>
              <div className="bg-[#FFFFFFB2] px-1.5 py-1 rounded-[15px] text-[#2C2C2C] text-xs">
                {t("Реклама")}
              </div>
            </div>
            <p className="text-white font-medium">
              {banner?.subtitle[locale as keyof typeof banner.subtitle]}
            </p>
          </div>
        </a>
      )}
    </div>
  )
}

export default Banner
