import Image from "next/image"
import Link from "next/link"
import React from "react"
import { MagazineType } from "../../pages/magazines/utils/models/magazineModel"
import {
  imageLayouts,
  imageLoadings,
  imageObjectFits,
} from "../../utils/constants/imageAttributes"
import { rootPaths } from "../../utils/constants/rootPaths"
import { makeUrl } from "../../utils/helpers/makeUrl"
import styles from "./MagazinesCont.module.scss"
import { useRouter } from "next/router"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { dayjsFormats } from "utils/constants/dayjsFormats"
import localeData from "dayjs/plugin/localeData"
import localizedFormat from "dayjs/plugin/localizedFormat"
import "dayjs/locale/ru"
import "dayjs/locale/uz-latn"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(customParseFormat)

dayjs.extend(advancedFormat)

type Props = {
  magazines: MagazineType[] | undefined
}

const MagazinesCont: React.FC<Props> = ({ magazines }) => {
  const { locale } = useRouter()

  return (
    <div className={styles.magazines_cont}>
      {magazines?.map((magazine, index) => (
        <Link
          href={`${rootPaths.MAGAZINES}/${makeUrl(
            magazine.slug ? magazine.slug : magazine.name
          )}-${magazine.id}`}
          key={index}
          legacyBehavior
        >
          <a className={styles.magazine}>
            <div className={styles.all}>
              <div className={styles.tags}>
                <span>
                  {
                    magazine.category?.name[
                      locale as keyof typeof magazine.category.name
                    ]
                  }
                </span>
              </div>
              <div className={styles.image}>
                <Image
                  src={magazine.image}
                  alt={magazine.name}
                  layout={imageLayouts.FILL}
                  objectFit={imageObjectFits.COVER}
                  loading={imageLoadings.LAZY}
                />
              </div>
              <div className={styles.more_info}>
                <h3 className={styles.title}>{magazine.name}</h3>
                <span className={styles.date}>
                  {locale === "uz"
                    ? dayjs(magazine.created_time, dayjsFormats.DATE)
                        .locale("uz-latn")
                        .format("DD MMMM YYYY")
                    : dayjs(magazine.created_time, dayjsFormats.DATE)
                        .locale("ru")
                        .format("DD MMMM YYYY")}
                </span>
              </div>
            </div>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default MagazinesCont
