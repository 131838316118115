import React, { cloneElement, useEffect, useRef } from "react"
import { useBannerSendView } from "../../pages/app/services/queries"
import { useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../../pages/app/utils/constants/queryKeys"

interface IProps {
  children: JSX.Element
  uuid?: string
}

const ViewAdv = ({ children, uuid }: IProps) => {
  const bodyRef = useRef<HTMLElement | null>(null)
  const qc = useQueryClient()
  useBannerSendView(uuid)

  function buildThresholdList() {
    const thresholds = []
    const numSteps = 20

    for (let i = 1.0; i <= numSteps; i++) {
      const ratio = i / numSteps
      thresholds.push(ratio)
    }

    thresholds.push(0)
    return thresholds
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e?.isIntersecting) {
          qc.fetchQuery([queryKeys.BANNER_VIEW(uuid)])
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: buildThresholdList(),
      }
    )

    if (bodyRef?.current) {
      observer.observe(bodyRef?.current)
    }

    return () => {
      if (bodyRef?.current) {
        observer.unobserve(bodyRef?.current)
      }
    }
  }, [bodyRef])

  return cloneElement(children, {
    ref: bodyRef,
  })
}

export default ViewAdv
