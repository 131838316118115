import { Grid } from "antd"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import React, { useEffect, useMemo } from "react"

import ComplexCard from "../../../../common/common-card/complex/ComplexCard"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { rootPaths } from "../../../../utils/constants/rootPaths"
import { viewTypes } from "../../../../utils/constants/viewTypes"
import { useGetTopResidentialComplexes } from "../../services/queries"

import Link from "next/link"
import { urls } from "utils/constants/urls"
import ViewAdv from "../../../../common/view-adv/ViewAdv"
import { useBanners } from "../../../app/services/queries"
import HotOffersMobile from "../hot-offers/HotOffersMobile"
import styles from "./NewComplexes.module.scss"

const MainNewComplexes: React.FC = () => {
  const t = useTranslations("body.newComplexes")
  const dispatch = useAppDispatch()
  const tMainTop = useTranslations("body.mainTop")
  const { locale } = useRouter()
  const { setUserAddress } = userReducerActions
  const { userAddress } = useAppSelector((state) => state.userReducer)
  const { data } = useGetTopResidentialComplexes()
  const { data: banners } = useBanners("main", locale)
  const { md } = Grid.useBreakpoint()

  useEffect(() => {
    dispatch(setUserAddress(userAddress))
  }, [userAddress])

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress])

  return (
    <section className={styles.new_buildings}>
      <div className="wrapper">
        <div className="mobile_only">
          <HotOffersMobile />
        </div>
        <h2 className={styles.title}>{t("Популярные новостройки")}</h2>
        <div className={styles.body}>
          {banners?.right_side?.images?.web?.url && (
            <ViewAdv uuid={banners?.right_side?.uuid}>
              <a
                className={styles.reklama_right}
                target={banners?.right_side?.blank ? "_blank" : "_self"}
                href={`${urls.BASE_ADS_UYSOT}/api/banner/view?uuid=${banners?.right_side?.uuid}&lang=${locale}`}
              >
                <div className={styles.reklama_tag}>
                  <span>{tMainTop("Реклама")}</span>
                </div>
                <img src={banners?.right_side?.images?.web?.url} alt="" />
              </a>
            </ViewAdv>
          )}
          {data?.data?.slice(0, 6)?.map((item) => (
            // <NonSSRWrapper key={item?.id}>
            <ComplexCard data={item} key={item?.id} viewType={viewTypes.GRID} />
            // </NonSSRWrapper>
          ))}
        </div>
        <div className={styles.bottom}>
          <Link href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`}>
            {t("Показать еще")}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default MainNewComplexes
