import React from "react"

interface IProps {
  stroke?: string
}

const ArrowUpIcon: React.FC<IProps> = ({ stroke = "#12B76A" }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50001 3.54171V13.4584M8.50001 13.4584L13.4583 8.50004M8.50001 13.4584L3.54168 8.50004"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowUpIcon
