import { dehydrate, QueryClient } from "@tanstack/react-query"
import { GetStaticPropsContext, NextPage } from "next"

import NextHead from "../src/common/next-head/NextHead"
import { queryKeys as appQueryKeys } from "../src/pages/app/utils/constants/queryKeys"
import Main from "../src/pages/main/Index"
import {
  fetchApartmentCounts,
  fetchCounts,
  fetchHotFilters,
  fetchTopBuilders,
  fetchTopComplexes,
  fetchTopMagazines,
} from "../src/pages/main/services/queryFunctions"
import { queryKeys } from "../src/pages/main/utils/constants/queryKeys"
import { useRouter } from "next/router"
import { fetchNewPaymentMethods } from "pages/apartments/services/queryFunctions"
import { fetchBanners } from "pages/app/services/queryFunctions"
import { $newapi } from "services/RequestService"
import { UZ } from "utils/constants/locales"

import "../src/styles/Common.scss"

const Index: NextPage = () => {
  const { locale } = useRouter()

  const title =
    locale === UZ
      ? "Toshkentdan kvartira sotib oling | Uysot.uz\n"
      : "Купить квартиру в новостройке в Ташкенте - Uysot.uz"
  const description =
    locale === UZ
      ? "Toshkentda yangi kvartira qidiryapsizmi? Uysot.uz saytida siz turar-joy majmualarining katta tanlovini topasiz. Ipoteka, muddatli to’lov, hamyonbop narxlar, boshlang’ich to’lovsiz uylar"
      : "Ищете новую квартиру в Ташкенте? На Uysot.uz вы найдете большой выбор новостроек от ведущих застройщиков. Ипотека, рассрочка, выгодные цены."
  const keyWords =
    locale === UZ
      ? "Toshkent kvartira, Toshkentda kvartira sotib olish, yangi uylar Toshkent, ko'chmas mulk Toshkent, ipoteka Toshkent, bo'lib to'lash Toshkent, Toshkent shahridagi uy-joylar, Toshkent markazidagi yangi uylar, Toshkent chekkasida yangi uylar, Toshkent novostroyka, Toshkent uylar narxi"
      : "купить квартиру в Ташкенте, новостройки Ташкент, недвижимость Ташкент, ипотека Ташкент, рассрочка Ташкент, квартиры в новостройках, жилье в Ташкенте, новостройки в центре Ташкента, новостройки на окраине Ташкента"

  return (
    <>
      <NextHead
        title={title}
        description={description}
        keywords={keyWords}
        ogDescription={description}
        ogTitle={title}
      />
      <Main />
    </>
  )
}

export default Index

export async function getStaticProps(context: GetStaticPropsContext) {
  const queryClient = new QueryClient()

  // counts
  await queryClient.prefetchQuery([queryKeys.COUNTS], fetchCounts)

  // apartment counts
  await queryClient.prefetchQuery(
    [queryKeys.APARTMENT_COUNTS],
    fetchApartmentCounts
  )

  // top builders
  await queryClient.prefetchQuery(
    [queryKeys.NEW_TOP_BUILDERS],
    fetchTopBuilders
  )

  await queryClient.prefetchQuery(
    [appQueryKeys.BANNER({ page_type: "main", lang: context.locale })],
    () => fetchBanners({ page_type: "main", lang: context.locale })
  )
  // top magazines
  await queryClient.prefetchQuery(
    [queryKeys.NEW_TOP_MAGAZINES, context.locale],
    () => fetchTopMagazines(context.locale)
  )

  // payment methods
  await queryClient.prefetchQuery(
    [appQueryKeys.PAYMENT_METHODS_SELECT],
    fetchNewPaymentMethods
  )

  // hot offers

  await queryClient.prefetchQuery([appQueryKeys.HOT_FILTERS], () =>
    fetchHotFilters()
  )

  await queryClient.prefetchQuery([queryKeys.MAIN_REGIONS], async () => {
    const res = await $newapi.get(`/city/select`)
    return res.data.data
  })

  const { url, fetch } = fetchTopComplexes()

  await queryClient.prefetchQuery([queryKeys.TOP_COMPLEXES, url], fetch);

  await queryClient.prefetchQuery([appQueryKeys.METROS],async () => {
    const res = await $newapi.get(`/metro-line/select`)
    return res.data.data
  })


  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      messages: (await import(`../messages/main/${context.locale}.json`))
        .default,
    },
    revalidate: false,
  }
}
