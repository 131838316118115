import React from "react"
import { useRouter } from "next/router"
import { layoutLang } from "utils/language/layout"

const SeoText = () => {
  const { locale } = useRouter()
  return (
    <section className="flex flex-col gap-6 max-w-[1230px] my-0 mx-auto md:px-0 px-4">
      <h2 className="text-[#2c2c2c] text-xl sm:text-2xl font-semibold">
        {layoutLang[String(locale)]["seo_title"]}
      </h2>
      <div
        className="text-[#475467] text-base font-medium bg-white rounded-3xl p-6"
        dangerouslySetInnerHTML={{
          __html: layoutLang[String(locale)]["seo_text"],
        }}
      />
    </section>
  )
}

export default SeoText
