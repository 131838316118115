import React from "react"

import MainMagazines from "./components/magazines/MainMagazines"
import MainNewComplexes from "./components/new-complexes/NewComplexes"
import MainTopBuilders from "./components/top-builders/MainTopBuilders"
import MainTop from "./components/top/MainTop"
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks"
import SeoText from "./components/seo-text/SeoText"
import HotOffers from "./components/hot-offers/HotOffers"
import { Grid } from "antd"
import { useRouter } from "next/router"
import { UZ } from "utils/constants/locales"
import NextHead from "common/next-head/NextHead"

const Index: React.FC = () => {
  const { md } = Grid.useBreakpoint();

  return (
      <div className="relative">
        <MainTop />
        <div className="desctop_only">
          <div className="flex justify-center gap-6 pl-6 mb-10 mr-[320px]">
            <HotOffers />
            <div>
              <MainNewComplexes />
              <MainUsefulLinks />
              <MainTopBuilders />
              <MainMagazines />
              <SeoText />
            </div>
          </div>
        </div>
        <div className="mobile_only">
          <MainNewComplexes />
          <MainUsefulLinks />
          <MainTopBuilders />
          <MainMagazines />
          <SeoText />
        </div>
      </div>
  )
}

export default Index
