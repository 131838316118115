export const queryKeys = {
  COUNTS: "counts",
  TOP_COMPLEXES: "top-complexes",
  TOP_BUILDERS: "top-builders",
  NEW_TOP_BUILDERS: "new-top-builders",
  NEW_TOP_MAGAZINES: "new-top-magazines",
  APARTMENT_COUNTS: "apartment-counts",
  MAIN_REGIONS: "MAIN_REGIONS",
  HOT_FILTERS: "HOT_FILTERS",
}
