import axios from "axios"
import $api, { $newapi } from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { urls } from "../../../utils/constants/urls"
import {
  BannerImageType,
  BannerObjectType,
  IBanner,
  IBannerImage,
} from "../utils/models/banner"

export const fetchNewSimilarComplexes =  (id?: string | null,payment_method_ids?:number[] | null,article_id?:number | null) => {
  let url = `/complex/similar`;

  if (id){
    url +=`?complex_id=${id}`
  }

  if(payment_method_ids && payment_method_ids?.length > 0){
    payment_method_ids.forEach(method_id => {
      url+=`?payment_method_id=${method_id}`
    })
  }

  if(article_id){
    url+=`?article_id=${article_id}`
  }
  return {
    fetch: async () => {
      const res = await $newapi.get(url)
      return res.data.data ?? []
    }
  }
}

export const fetchNewSimilarApartments =  (id?: string | null,payment_method_ids?:number[] | null,article_id?:number | null) => {
  let url = `/apartment/similar`;

  if(id){
    url+`?apartment_id=${id}`
  }

  if(payment_method_ids && payment_method_ids?.length > 0){
    payment_method_ids.forEach(method_id => {
      url+=`?payment_method_id=${method_id}`
    })
  }
  if(article_id){
    url+=`?article_id=${article_id}`
  }

  return{
    fetch:async()=>{
      const res = await $newapi.get(url)
      return res.data.data ?? []
    }
  }
}

export const fetchNewComplexesOfBuilder = async (
  id: string,
  builderId?: number
) => {
  const res = await $newapi.get(
    `/complex/view?not_complex_id=${id}&builder_id=${builderId}`
  )
  return res.data.data ?? []
}

// banners
export const fetchBanners = async ({
  page_type,
  lang,
}: {
  page_type?: string
  lang?: string
}) => {
  const res = await axios.get(
    `${urls.BASE_ADS_UYSOT}${endpoints.API_BANNER_VIEW({ page_type, lang })}`
  )

  const data: IBanner[] = res.data.data

  const convertData = data?.reduce<BannerObjectType>((p, c) => {
    const images = c?.images
      ?.filter((item) => item?.language_code === lang)
      ?.reduce<Record<BannerImageType, IBannerImage>>((imgP, imgC) => {
        imgP[imgC?.type] = imgC
        return imgP
      }, {} as Record<BannerImageType, IBannerImage>)

    p[c.position] = {
      ...c,
      images,
    }
    return p
  }, {} as BannerObjectType)

  return convertData
}
