import { useTranslations } from "next-intl"
import Link from "next/link"
import React from "react"

import PaginationNextIcon from "../../../../assets/icons/PaginationNextIcon"
import MagazinesCont from "../../../../common/magazinesCont/MagazinesCont"
import { rootPaths } from "../../../../utils/constants/rootPaths"
import { useGetTopMagazines } from "../../services/queries"

import styles from "./MainMagazines.module.scss"
import { Grid } from "antd"
import MagazinesMobile from "pages/main/components/magazines/MagazinesMobile"

const MainMagazines: React.FC = () => {
  const t = useTranslations("body.magazines")
  const { data } = useGetTopMagazines()
  const { md } = Grid.useBreakpoint()

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2 className={styles.title}>{t("Журнал UYSOT")}</h2>
          <Link href={rootPaths.MAGAZINES} legacyBehavior>
            <a>
              {t("Показать все")} <PaginationNextIcon />
            </a>
          </Link>
        </div>
        <div className="desctop_only">
          <MagazinesCont magazines={data} />
        </div>
        <div className="mobile_only">
          <MagazinesMobile magazines={data}/>
        </div>
      </div>
    </section>
  )
}

export default MainMagazines
